.App {
  text-align: center;
}

body {
  padding-top: 80px;
}
@media (max-width: 600px) {
  body {
    padding-top: 120px;
  }
}

.nl2br {
  white-space: pre-line;
}

.pointer {
  cursor: pointer;
}

.btn-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.washout {
  opacity: 0.7;
}

.washout-disabled {
  opacity: 0.4;
}
