$background-1: #3d9cb9;
$background-2: #64b0c7;
$background-3: #83c0d2;
$background-4: #9ccddb;
$background-5: #b0d7e2;
$text-1: #1f3155;

$body-bg: #b0d7e2;

$theme-colors: (
  "primary": $text-1
);


.navbar {
  background-color: $background-1;
}

.navbar-light .navbar-nav a.nav-link {
  color: $text-1;
}

.navbar .btn-outline-navbar {
  border-color: white;
}

.card-header.accordion-heading {
  background-color: $background-2;
};

footer {
  background-color: $background-1;
}


@import "node_modules/bootstrap/scss/bootstrap";

@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

/* Optional, but what for are they needed?
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/code";
@import "node_modules/bootstrap/scss/grid";
*/
